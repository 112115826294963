// Here you can add other styles
body {
  font-family: 'Inter', sans-serif;
  color: #000;
  min-height: 100vh;
  line-height: 16px !important;
}

#root {
  min-height: 100vh;
  background-image: url('https://s3.ap-southeast-1.amazonaws.com/dev.thegrowhub.co/common/page-background.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
}
body::-webkit-scrollbar,
.sidebar-nav::-webkit-scrollbar,
.gh-scrollbar::-webkit-scrollbar {
  width: 0.3em;
}
body::-webkit-scrollbar-track,
.sidebar-nav::-webkit-scrollbar-track,
.gh-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb,
.sidebar-nav::-webkit-scrollbar-thumb,
.gh-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(67, 67, 67);
  outline: 1px solid rgb(60, 73, 86);
  margin-left: 0.25rem;
}
.register .row {
  // box-shadow: 0px 5px 15px 5px rgb(0 0 0 / 11%);
  border-radius: 30px;
  background: #fff;
}
form .validation-error {
  color: red;
  font-size: 15px;
  padding-left: 10px;
}
.register .form-text {
  padding-left: 10px;
}

.forgot-password .row,
.signup-verify-page .row {
  box-shadow: 0px 5px 15px 5px rgb(0 0 0 / 11%);
  border-radius: 30px;
  padding: 70px 20px 50px;
  position: relative;
}
.left-box {
  background: #fafafa;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  display: flex;
  flex-direction: column;

  padding: 70px;
}
.left-box h2 {
  margin: 20px 0 10px;
}
.left-box .btn-primary {
  margin-top: 20px;
}
.right-box,
.right-box-jap {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  min-height: 600px;
}

.right-box form {
  width: 100%;
}
.right-box h4 {
  color: #0eaf00;
  margin-bottom: 20px;
}
.btn-primary {
  background: #0eaf00;
  border: 2px solid #0eaf00;
  border-radius: 4px;
  padding: 10px 25px;
}
.btn-primary:hover {
  background: #fff;
  color: #0eaf00;
  border: 2px solid #0eaf00;
}
.btn-primary:active,
.btn-primary:focus {
  background: #0eaf00 !important;
  border: 2px solid #0eaf00 !important;
  color: #fff;
}
.btn-draft {
  background: #8b968a !important;
  border-color: #8b968a;
}
.btn-draft:hover {
  background: #fff !important;
  color: #8b968a;
  border: 2px solid #8b968a;
}
.btn-draft:active,
.btn-draft:focus {
  background: #8b968a !important;
  border: 2px solid #8b968a !important;
  color: #fff;
}
.btn-back {
  background: #000 !important;
  border-color: #000;
}
.btn-back:hover {
  background: #fff !important;
  color: #424f65;
  border: 2px solid #424f65;
}
.btn-back:active,
.btn-back:focus {
  background: #424f65 !important;
  border: 2px solid #424f65 !important;
  color: #fff;
}
input.form-control,
textarea.form-control {
  font-size: 14px;
  border-color: #e7e7e7;
  padding: 10px;
  border-radius: 5px;
}
.input-group {
  margin-bottom: 10px;
}
span.require-star {
  color: red;
}

.password-input-wrapper {
  position: relative;
  .eye-icon {
    position: absolute;
    height: 22px;
    width: 22px;
    right: 8px;
    top: 12px;
    z-index: 1;
  }
}

.forgot-pw {
  font-size: 13px;
  text-align: right;
  display: block;
  margin-bottom: 15px;
  a {
    text-decoration: underline;
    color: maroon;
  }
}
input#invalidCheck {
  margin-right: 5px;
}
input#invalidCheck:checked {
  background-color: #0eaf00;
  border-color: #0eaf00;
}
.form-check-label {
  font-size: 13px;
}
.sidebar.sidebar-fixed {
  background: #17a346;
}

@media (min-width: 768px) {
  .forgot-password.container,
  .signup-verify-page.container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .forgot-password.container,
  .signup-verify-page.container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .forgot-password.container,
  .signup-verify-page.container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .forgot-password.container,
  .signup-verify-page.container {
    max-width: 1320px;
  }
}

.forgot-password,
.signup-verify-page {
  background: #fafafa;
  border-radius: 30px;
}
img.custom-icon {
  width: 60px;
  margin-bottom: 20px;
}
img.check-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}
.forgot-password p {
  margin: 10px 0 30px;
}
.forgot-password .logo,
.signup-verify-page .logo {
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 100%;
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.11);
  height: 100px;
  position: absolute;
  top: -50px;
  width: 100px;
}
.signup-verify-page .resend-email-btn {
  color: #2eb85c;
  cursor: pointer;
}
.signup-verify-page .resend-email-btn:disabled {
  color: #aaaaaa;
  cursor: not-allowed;
  pointer-events: none;
}

.forgot-password .cooldown-text {
  color: #aaaaaa;
}

.form-control:focus,
.form-select:focus {
  color: var(--cui-input-focus-color, rgba(44, 56, 74, 0.95));
  background-color: var(--cui-input-focus-bg, #fff);
  border-color: #2eb85c;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #2eb85c4f;
  border-radius: 0;
}
.eye-icon {
  cursor: pointer;
}
span.error-text {
  color: red;
  display: block;
  margin: -10px 0px 10px;
  font-size: 12px;
}
.error {
  color: red;
  font-size: 13px;
}
a {
  color: #2eb85c;
}
.filterform {
  display: flex;
  flex-direction: row;
}
.filterform select.form-select {
  font-size: 14px;
  padding: 10px 15px;
  border-color: #e7e7e7;
}
button.btn-medium {
  padding: 8px 20px;
  font-size: 14px;
}
.dwPlXY {
  padding: 5px 0;
}
.form-label {
  font-size: 14px;
}
.form-container {
  padding: 30px 0 30px;
  background: #fff;
  border-radius: 10px;
}
.form-container h5 {
  color: #2eb85c;
  margin-bottom: 10px;
}
label {
  font-size: 14px;
}
.sidebar-nav .nav-group.show {
  background: rgb(201 201 201 / 18%);
}
.nav-link {
  font-size: 14px;
}
.mediaform {
  padding: 40px 0;
  background: #fff;
  border-radius: 10px;
}
.media-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
}
.gallery__item label {
  width: 100%;
  position: absolute;
  height: 100%;
}
.gallery__item label input {
  display: none;
}
.modal.show .modal-dialog {
  // top: 30%;
  position: relative;
}
.media-container {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
}
.media-container:hover .overlay,
.media-container:hover img.edit,
.media-container:hover img.delete,
.gallery__item:hover .overlay,
.gallery__item:hover img.edit,
.gallery__item:hover img.delete {
  opacity: 1;
}
.overlay {
  width: 100%;
  height: 100%;
  background: rgb(88 88 88 / 75%);
  opacity: 0;
  position: absolute;
}
img.edit {
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 0;
  cursor: pointer;
}
img.delete {
  position: absolute;
  right: 10px;
  bottom: 10px;
  opacity: 0;
  cursor: pointer;
}
.edit-media .modal-dialog {
  max-width: 990px;
}
.type-info span {
  font-size: 14px;
}
.type-title {
  color: #878787;
  font-size: 12px !important;
}
.modal-footer,
.modal-header {
  border: 0;
}
.profile-tab a.nav-link.active {
  background: #4fa34459;
  color: #333333;
  font-weight: 500;
}
.profile-tab a.nav-link {
  color: #333333;
  font-weight: 500;
}
select.form-select {
  font-size: 14px;
  border-color: #e7e7e7;
  padding: 10px !important;
  border-radius: 0;
  margin-bottom: 10px;
}
.table td {
  font-size: 13px;
  padding: 15px 10px;
}
.table thead td,
th {
  font-weight: 500;
  font-size: 13px;
}
ul.pagination {
  float: right;
}
.page-link {
  color: #2eb85c;
}
img.delete-icon {
  cursor: pointer;
}
input.ReactTags__tagInputField {
  font-size: 14px;
  padding: 10px;
  border-radius: 0;
  margin-bottom: 20px;
  appearance: none;
  width: 100%;
  box-shadow: none;
  border: 1px solid #e7e7e7;
}
input.ReactTags__tagInputField:focus-visible {
  border: 1px solid #e7e7e7 !important;
}
span.tag-wrapper.ReactTags__tag {
  font-size: 13px;
  color: #12823c;
  margin: 5px 15px 5px 0px;
  display: inline-flex;
}
button.ReactTags__remove {
  margin: 0 5px;
  border: 1px solid white;
  border-radius: 5px;
}
a.header-brand.d-md-none {
  position: absolute;
  left: 50px;
}
.header-brand img {
  margin-left: -20px;
  height: 50px;
}
button.header-toggler.ps-1 {
  position: absolute;
  left: 10px;
  z-index: 99;
}
.disable-color {
  color: #bebebe;
}
.btn:disabled,
.btn.disabled {
  background-color: #717171 !important;
  border-color: #717171 !important;
}
.header-nav .dropdown-menu {
  cursor: pointer;
}
.add-product-form input.form-control,
.add-product-form textarea.form-control {
  border-color: #e7e7e7 !important;
  background-image: none !important;
}
body.modal-open {
  overflow: scroll !important;
}
.asset-gallery .modal-dialog {
  max-width: 80%;
}
.preview-image img {
  width: 50%;
}
.multiple-gallery {
  width: 25%;
}
.multiple-gallery label {
  display: block;
  margin: 2%;
}
.multiple-gallery .media-container {
  width: 100%;
  height: 200px;
}
input[type='checkbox']:checked + .media-container .overlay {
  opacity: 1;
  background: rgb(160 160 160 / 61%);
}
.certificate-img .preview-image img {
  width: 100%;
}
.certificate-img {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  margin-bottom: 20px;
}
.certificate-img .preview-image {
  width: 30%;
  position: relative;
  margin-right: 5px;
  cursor: move;
}
.single-image {
  position: relative;
  width: 100%;
}
.certificate-img img.delete,
.single-image img.delete {
  width: 15px;
  opacity: 1;
  z-index: 1;
  cursor: pointer;
}
.preview-image:hover .overlay,
.single-img .preview-image img:hover .overlay {
  opacity: 1;
}
.preview-image .overlay {
  position: absolute;
  opacity: 0;
  top: 0;
}
.multiple-gallery input {
  opacity: 0;
}
.product-preview ul {
  list-style-type: none;
  margin-left: -40px;
}
.product-preview ul li {
  font-size: 14px;
}
.product-preview h6 {
  font-weight: 600;
}
.product-preview .secondary-img {
  width: 100px;
}
ul.ing-list {
  display: inline-block;
  margin-top: 0;
}
.img-container {
  display: inline-flex;
}
span.preview-click {
  color: #2eb85c;
  cursor: pointer;
}
span.preview-click:hover {
  color: #2819af;
}
.form-switch .form-check-input:checked {
  background-color: #2eb85c;
  border-color: #4fa344;
}
.custom-dropdown {
  background: none !important;
  color: #333 !important;
  font-size: 13px;
  border-radius: 0;
  border-color: #e7e7e7 !important;
}
.custom-dropdown:focus {
  background: none !important;
  color: #333 !important;
  border-color: #e7e7e7 !important;
}
ul.dropdown-menu.show.custom-dropdown-menu {
  font-size: 13px;
  width: 100%;
  border-radius: 0;
}
ul.dropdown-menu.show.custom-dropdown-menu .dropdown-item:active {
  background: none !important;
  color: #333 !important;
}
button.btn.btn-primary.dropdown-toggle.custom-dropdown.disabled {
  background-color: #d8dbe0 !important;
  border-color: #d8dbe0 !important;
  opacity: 1;
}
button.btn.btn-primary.dropdown-toggle.custom-dropdown.disabled {
  background-color: #d8dbe0 !important;
  border-color: #d8dbe0 !important;
  opacity: 1;
}
.custom-tab li.nav-item {
  width: 33.33%;
  text-align: center;
}
.custom-tab li.nav-item a {
  font-weight: 600;
  color: #333333 !important;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.custom-tab a.nav-link.active {
  background: rgb(79 163 68 / 35%);
  border: rgb(79 163 68 / 35%);
}
.custom-tab-content h6 {
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.custom-tab-content p {
  font-size: 14px;
}
.ing-preview-list {
  list-style: none;
  font-size: 14px;
  margin-left: -40px;
}
.custom-image img {
  width: 50%;
}
.custom-tab-content a {
  text-decoration: underline;
}
.custom-tab-content .fit-width-btn a {
  text-decoration: none;
  font-size: 14px;
}
.custom-image-box {
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.custom-image-box div {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 5px 5px 5px;
}
.custom-image-box div img {
  width: 100%;
}
.custom-image {
  padding: 0;
}
.btn-group.fit-width-btn {
  width: 100%;
}
.fit-width-btn button.btn.btn-primary.dropdown-toggle.disabled {
  width: 100%;
  font-size: 14px;
  color: #fff;
}
.fit-width-btn button.btn.btn-primary.dropdown-toggle {
  font-size: 14px;
  background: transparent;
  color: #000;
}
select.form-select.current-holding-select {
  background: #ccc;
  background-image: none !important;
  border-color: #ccc !important;
}
.current-holding-select option {
  background: #fff;
}
button.btn-white {
  background: #fff;
  color: #2eb85c;
}
.add-product-form label {
  font-weight: 600;
  text-transform: uppercase;
  color: #333;
}
p.second-label {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  color: gray;
}
.youtube-link span {
  font-size: 13px;
  color: blue;
  display: flex;
  margin-right: 8px;
  align-items: center;
}
.youtube-link input.form-control {
  width: 100%;
  max-width: 100%;
}
.w-100-youtube input {
  width: 100% !important;
  max-width: 100% !important;
}
.tags-input-container {
  border: 1px solid #e7e7e7;
  padding: 0.3em;
  border-radius: 3px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
  font-size: 13px;
}

.tag-item {
  background-color: rgb(218, 216, 216);
  display: inline-block;
  padding: 0.5em 0.75em;
  border-radius: 20px;
  font-size: 12px;
}
.tag-item .close {
  height: 20px;
  width: 20px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 12px;
  cursor: pointer;
}

.tags-input {
  flex-grow: 1;
  padding: 0.5em 0;
  border: none;
  outline: none;
}
button.transfer-btn {
  background: none;
  border: none;
  color: #4fa344;
  text-decoration: underline;
}
.inventory-table .hbotQl {
  font-weight: 600;
  font-size: 14px;
}
.grid-gallery {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(188px, 1fr));
  grid-auto-rows: 188px;
  grid-auto-flow: dense;
  // max-width: 1200px;
  margin: 10px auto;
  // padding: 0 10px;
}

.gallery__item {
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #ccc;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain
  }
}

.asset-gallery .modal-header button.btn.btn-close {
  right: 34px;
  position: absolute;
}

/**
 * START react-datepicker
 */

.react-datepicker__input-container {
  border: 1px solid #e7e7e7;
  padding: 9px;
  border-radius: 5px;
  width: 100%;
  font-size: 14px;

  input {
  }
}
.react-datepicker-popper {
  background-color: var(--gh-content);
  border-color: var(--gh-divider);

  @mixin selected-date {
    background-color: var(--gh-brand) !important;
    color: var(--gh-primary-foreground) !important;
    border-radius: 0.5rem;
  }

  .react-datepicker__header {
    background-color: var(--gh-content);
  }

  .react-datepicker__triangle {
    fill: var(--gh-content) !important;
    color: var(--gh-content) !important;
    stroke: var(--gh-divider) !important;
  }

  .react-datepicker__header {
    border-color: var(--gh-divider);
  }

  .react-datepicker {
    border-color: var(--gh-divider) !important;
  }

  .react-datepicker__week {
    display: flex;
  }

  .react-datepicker__day {
    width: 2rem;
    height: 2rem;
    margin: 0rem;
    border-radius: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &,
    &--in-range {
      &:not([aria-disabled='true']):hover {
        color: var(--gh-primary-foreground) !important;
        font-weight: normal;
        position: relative;
        z-index: 0;
        border-radius: 0.5rem;

        &:not(.react-datepicker__day--outside-month)::after {
          content: '';
          width: 2rem;
          height: 2rem;
          position: absolute;
          background-color: var(--gh-brand) !important;
          z-index: -1;
          border-radius: 0.5rem;
        }
      }
    }

    &--keyboard-selected {
      color: var(--gh-primary-foreground) !important;
      font-weight: normal;
      position: relative;
      z-index: 0;

      &:not(.react-datepicker__day--outside-month)::after {
        content: '';
        width: 2rem;
        height: 2rem;
        position: absolute;
        background-color: var(--gh-brand) !important;
        z-index: -1;
        border-radius: 0.5rem;
      }
    }
  }

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__day--in-range {
    @apply text-body-xs;
    background-color: var(--gh-brand-light);
    color: var(--gh-content-foreground);
  }

  .react-datepicker__day--range-end,
  .react-datepicker__day--range-start,
  .react-datepicker__day--selected {
    color: var(--gh-primary-foreground) !important;
    font-weight: normal;
    position: relative;
    z-index: 0;

    &:not(.react-datepicker__day--outside-month)::after {
      content: '';
      width: 2rem;
      height: 2rem;
      position: absolute;
      background-color: var(--gh-brand) !important;
      z-index: -1;
      border-radius: 0.5rem;
    }
  }
}

/**
 * END react-datepicker
 */

/**
 * START react-day-picker
 */
:root {
  .rdp-root {
    --rdp-accent-color: var(--gh-brand);
    --rdp-accent-background-color: var(--gh-brand-light);
    --rdp-font-family: inherit;
    --rdp-day-font: inherit;
    --rdp-day-height: 2rem;
    --rdp-day-width: 2rem;
    --rdp-day_button-border-radius: 0.5rem;
    --rdp-day_button-border: none;
    --rdp-day_button-height: var(--rdp-day-height);
    --rdp-day_button-width: var(--rdp-day-width);
    --rdp-selected-border: none;
    --rdp-selected-font: 400 0.875rem 0.875rem var(--rdp-font-family);
    --rdp-disabled-opacity: 0.5;
    --rdp-outside-opacity: 0.75;
    --rdp-today-color: var(--rdp-accent-color);
    --rdp-dropdown-gap: 0.5rem;
    --rdp-month_caption-font: 400 0.875rem 0.875rem var(--rdp-font-family);
    --rdp-months-gap: 0;
    --rdp-nav_button-disabled-opacity: 0.5;
    --rdp-nav_button-height: 1rem;
    --rdp-nav_button-width: 1rem;
    --rdp-nav-height: 1rem;
    --rdp-range_middle-background-color: var(--rdp-accent-background-color);
    --rdp-range_middle-font: normal medium var(--rdp-font-family);
    --rdp-range_middle-foreground-color: white;
    --rdp-range_middle-color: inherit;
    --rdp-range_start-color: var(--gh-brand-foreground);
    --rdp-range_start-background: linear-gradient(
      var(--rdp-gradient-direction),
      transparent 50%,
      var(--rdp-range_middle-background-color) 50%
    );
    --rdp-range_start-date-background-color: var(--rdp-accent-color);
    --rdp-range_end-background: linear-gradient(
      var(--rdp-gradient-direction),
      var(--rdp-range_middle-background-color) 50%,
      transparent 50%
    );
    --rdp-range_end-color: var(--gh-brand-foreground);
    --rdp-range_end-date-background-color: var(--rdp-accent-color);
    --rdp-week_number-border-radius: 100%;
    --rdp-week_number-border: none;
    --rdp-week_number-font: 400 0.875rem 0.875rem var(--rdp-font-family);
    --rdp-week_number-height: var(--rdp-day-height);
    --rdp-week_number-opacity: 0.75;
    --rdp-week_number-width: var(--rdp-day-width);
    --rdp-weeknumber-text-align: center;
    --rdp-weekday-font: 400 0.875rem 0.875rem var(--rdp-font-family);
    --rdp-weekday-opacity: 0.75;
    --rdp-weekday-padding: 0;
    --rdp-weekday-text-align: center;
    --rdp-gradient-direction: 90deg;

    .rdp-month_grid {
      margin: 0.5rem 1rem 1rem;
    }
  }
}

/**
 * END react-day-picker
 */

/**
 * START apexcharts
 */

.apexcharts-xaxistooltip,
.apexcharts-tooltip {
  border-radius: 0.5rem;
  border: none !important;
  color: var(--gh-default-foreground) !important;
  background-color: var(--gh-brand-light) !important;
}

.apexcharts-xaxistooltip {
  &-bottom {
    &::before,
    &::after {
      border-bottom-color: var(--gh-brand-light) !important;
    }
  }
}

/**
 * END apexcharts
 */

span.grid-name {
  color: #fff;
  text-align: center;
  width: 100%;
  align-items: end;
  display: flex;
  height: 100%;
  justify-content: center;
  text-transform: capitalize;
  margin-top: -5px;
}
.grid-gallery .overlay {
  cursor: pointer;
}
.lightbox-media .modal-content {
  overflow: visible;
  max-height: 100%;
}
img.refresh {
  width: 20px;
  cursor: pointer;
  height: 20px;
  margin-left: 10px;
  -moz-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
img.refresh:focus,
img.refresh:active {
  transform: rotate(180deg);
}
.holding-entity .btn-group {
  width: 100%;
}
@media only screen and (max-width: 990px) {
  .logo {
    width: 150px;
  }
  .filterform select.form-select {
    font-size: 13px;
    padding: 10px 10px;
  }
}
@media only screen and (max-width: 767px) {
  .lightbox-media .modal-content,
  .edit-media .modal-content {
    width: 100%;
  }
  .gallery {
    display: block;
  }
  .gallery__item {
    margin-bottom: 10px;
  }
  .gallery__item:nth-child(1) {
    grid-column: none;
  }
  .gallery__item:nth-child(4) {
    grid-row: none;
  }
  .gallery__item:nth-child(5) {
    grid-column: none;
    grid-row: none;
  }
  .list.d-flex.flex-wrap {
    display: flex !important;
  }
  .secondary_buttons .col-xl-8.col-lg-8.col-md-8.d-flex,
  .secondary_buttons .col-xl-6.col-lg-6.col-md-6.d-flex {
    display: block !important;
  }
  .secondary_buttons .w-50 {
    width: 100% !important;
  }
  .youtube-link span {
    margin-bottom: 10px;
  }
  .youtube-link input.form-control {
    width: 100%;
    max-width: 100%;
  }
  .initial-entity label {
    display: block;
  }
  .initial-entity .btn-group {
    width: 100%;
    margin-bottom: 10px;
  }
  .ing-container {
    width: 100% !important;
  }
  .header > .container-fluid:first-child {
    justify-content: flex-end;
  }
  .logo {
    margin-top: -100px;
  }
  .forgot-password .logo,
  .signup-verify-page .logo {
    margin-top: 0;
  }
  .register {
    padding: 90px 30px 50px;
  }
  .left-box {
    background: #fff;
    border-radius: 30px;
    padding: 50px 20px;
  }
  .right-box {
    background: #fff;
    border-radius: 30px;
    padding: 0px 30px 50px;
  }
  .right-box form {
    width: 100%;
  }
  .right-box h4 {
    text-align: center;
  }
  // span.forgot-pw {
  //   text-align: left;
  //   margin-bottom: 10px;
  // }
  .filterform {
    display: block;
  }
  .filterform select.form-select {
    margin-bottom: 10px;
  }
  .form-container .d-flex {
    display: block !important;
  }
  .mediaform .form-group {
    width: 100% !important;
  }
  .multiple-gallery .media-container {
    width: 100% !important;
  }
  .filter-search input {
    width: 100% !important;
  }
  .asset-gallery .modal-dialog {
    max-width: 100%;
  }
  .asset-gallery .modal-content {
    padding-left: 0;
    padding-right: 0;
  }
}

.swal2-popup.swal2-toast {
  padding: 16px !important;
}
body.swal2-toast-shown .swal2-container {
  max-width: 40% !important;
}

/***** buttons *****/

// this class should be slowly expanded to include more and more buttons, until
// it can be phased out entirely in favour of just ".btn"
.btn.btn-primary .btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus,
.btn.btn-secondary {
  border-radius: 4px;
  border-style: solid;
  border-width: 4px;
  padding: 10px 30px;
}

$primary-color: #0eaf00;
$primary-inverse-color: #ffffff;

$secondary-color: #8390bb;
$secondary-inverse-color: #ffffff;

.btn.btn-primary,
.btn.btn-primary:active,
.btn.btn-primary:focus,
.btn.btn-primary:hover:focus {
  background-color: $primary-color;
  border-color: $primary-color;
  color: $primary-inverse-color;
}
.btn.btn-primary:hover {
  background-color: $primary-inverse-color;
  border-color: $primary-color;
  color: $primary-color;
}

.btn.btn-secondary,
.btn.btn-secondary:active,
.btn.btn-secondary:focus,
.btn.btn-secondary:hover:focus {
  background-color: $secondary-color;
  border-color: $secondary-color;
  color: $secondary-inverse-color;
}
.btn.btn-secondary:hover {
  background-color: $secondary-inverse-color;
  border-color: $secondary-color;
  color: $secondary-color;
}
/***** /buttons *****/

/***** /header *****/
.header {
  --cui-header-bg: var(--gh-content);
}
/***** /header *****/

/***** /card *****/
.card {
  --cui-card-bg: var(--gh-content);
}
/***** /card *****/

/***** /modal *****/
.modal-content {
  --cui-modal-bg: var(--gh-content);
}
/***** /modal *****/
