//Import fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

// If you want to override variables do it here
@import 'variables';

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import '@coreui/coreui/scss/coreui';

// Import Chart.js custom tooltips styles
@import '@coreui/chartjs/scss/coreui-chartjs';

@import 'layout';
@import 'example';

$background-primary: #4fa344;
$primary: #4fa344;
$red: #dc3545;
$primary-dark: #0e743b;
$primary-light: #6dae64;
$black: #333;

$h1-font-size: 56px;
$h2-font-size: 48px;
$h3-font-size: 32px;
$h4-font-size: 24px;
$h5-font-size: 18px;
$h6-font-size: 16px;

$breakpoints: (
  sm: 480px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1400px,
);
@mixin breakpoint($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Invalid breakpoint `#{$name}`.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}

.color-grey {
  color: #8a8a8a;
}

li {
  list-style-type: none;
}

// If you want to add custom CSS you can put it here.
@import 'custom';

@import 'nav';
@import 'organisations';
@import 'new-organisation';
@import 'members';

@import 'permissions';
@import 'edit-organistion';
@import 'swal';
@import 'batches';
@import 'overview';
@import 'login';
@import 'products.scss';
@import 'media';
@import 'custom-version-2';

// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

.wrapper {
  position: relative;
}

.font-secondary {
  font-family: 'Quicksand', sans-serif;
}

input {
  // border-radius: 5px !important;
  // border: 1px solid #aaa !important;
}

.popup-overlay {
  z-index: 3000 !important ;
  background: rgba(255, 255, 255, 0.5) !important;
}

.container-fluid {
  position: relative;
}

.container {
  max-width: 100%;
}

.btn-width {
  min-width: 150px;
}

.btn-red {
  background: $red !important;
  border: 1px solid $red;
  &:hover {
    background: white !important;
    color: $red;
    border: 1px solid $red !important;
  }
}
.text-color-red {
  color: $red;
}

.body {
  padding: 1rem;
  display: flex;
}

.error {
  color: $red;
}

.container-fluid {
  min-height: 100%;
  padding: 1rem;
  flex: 1 1;
}

small {
  font-size: 12px;
}

h1 {
  font-size: $h1-font-size;
  font-weight: 600;
  margin: 0;
  color: $black;
}
h2 {
  font-size: $h2-font-size;
  font-weight: 600;
  margin-bottom: 2rem;
  color: $black;
}
h3 {
  font-size: $h3-font-size;
  font-weight: 600;
  margin: 1rem 0;
  color: $black;
}
h4 {
  font-size: $h4-font-size;
  font-weight: 600;
  margin: 1.2rem 0;
  color: $black;
}
h5 {
  font-size: $h5-font-size;
  font-weight: 400;
  margin: 1rem 0;
  color: $black;
}
h6 {
  font-size: $h6-font-size;
  margin: 0;
  color: $black;
}

.btn-primary {
  background: $primary;
  color: white;
}

a {
  text-decoration: none;
}

.visible {
  display: block;
}

.modal {
  display: block;
  position: relative;
  padding: 2rem;
  border: none;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.modal-content {
  padding: 2rem;
  text-align: center;
  border: none;
}

.popup-content {
  max-width: 600px;
  border: none !important;
}

.btn-outline:hover {
  border: 2px solid #aaa;
}

.modal-content .actions {
  justify-content: center;
}

a {
  cursor: pointer;
}

.modal {
  position: fixed;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.modal-dialog {
  width: 100%;
}

.border-rounder {
  border-radius: 15px !important;
}

.vh100 {
  min-height: 100vh;
}
