.right-box {
  background-image: url('../assets/images/With_ease.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.right-box-jap {
  background-image: url('../assets/images/With_ease_jap.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.right-box-register {
  background-image: url('../assets/images/Scale_up.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.left-box .logo {
  mix-blend-mode: multiply;
}

.banner-url {
  display: block;
  width: 100%;
  height: 100%;
}

.register-prompt {
  text-decoration: underline;
}

.register {
  overflow: hidden;
  border-radius: 30px;
}
