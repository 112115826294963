.modal-content {
  width: calc(100% - 4rem);
  @include breakpoint(md) {
    width: 100%;
  }
  max-height: 90vh;
  overflow-y: scroll;
}

.asset-gallery {
  .pagination-wrapper {
    width: fit-content;
    min-width: 600px;
    flex: 0 0;
  }
  .options {
    flex-direction: column;
    @include breakpoint(md) {
      flex-direction: row-reverse;
    }

    display: flex;
    justify-content: end;
    margin-right: 0.5rem;

    button {
        margin-bottom: 1rem;
        @include breakpoint(md) {
            margin-bottom: 0;
        }
    }
  }
}
