.image-box {
  border: 1px solid gray;
  border-radius: 20px;
  overflow: hidden;
  min-height: 500px;
  max-width: 625px;
  max-height: 650px;
  height: 100%;
  width: 100%;
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 4rem;
  @include breakpoint(md) {
    margin-bottom: 0;
  }
}

.page-options {
    margin-bottom: 2rem;
    @include breakpoint(md) {
        marign: 0;
    }
}
.video-player {
    width: 100%;
    @include breakpoint(md) {
        width: 50%;
    }
    margin: 0 auto;
    margin-top: 6rem !important;
}


.brand-logo {
  margin-left: 2rem;
}

.overview-container {
  max-width: 1280px;
  margin: 0 auto;

  h5 strong {
    padding-right: .5rem;
  }
}



.text-medium {
  font-weight: 500;
}

.overview-img {
  @include breakpoint(md) {
    margin: 1rem
  }
  margin: .5rem;
  height: 125px;
  min-width: 125px !important;
  border-radius: 10px;
  border: 1px solid gray;
}
.text-larger {
  font-size: 16px !important;
}
.w-50 {
  max-width: 50% !important;
}

.ingredients-tag {
  padding: 0.5rem 1rem;
  background: $primary;
  border-radius: 20px;
  color: white;
  margin-left: 0.5rem;
}
.attributes-tag {
  padding: 0.5rem 1rem;
  background: $secondary;
  border-radius: 20px;
  color: white;
  margin-left: 0.5rem;
}

.certs {
  margin: 1rem;
}

.max-width-600 {
    padding: 0;
    @include breakpoint(md) {
        padding-left:2rem;
    }
  max-width: 625px;
}

.container-content {
    padding: 0;
  @include breakpoint(md) {
    padding: 1rem 2rem;
  }
  
}

.additional-links {
  padding-right: .5rem;
}