// ============ BUTTON ============ //
.CV2-btn {
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid var(--gh-primary);
  font-weight: 600;

  &.btn-primary {
    background-color: var(--gh-primary);
    border-color: var(--gh-primary);
    padding: 9px 12px;

    &:hover,
    &:focus {
      background-color: var(--gh-primary) !important;
      border: 1px solid var(--gh-primary) !important;
      color: var(--gh-brand-foreground);
      padding: 9px 12px;
      border-radius: 6px;
    }

    &:disabled {
      background-color: var(--gh-muted) !important;
      border: none !important;
      color: var(--gh-muted-foregroundLight) !important;
    }
  }

  &.btn-secondary {
    background-color: var(--gh-secondary-background) !important;
    color: var(--gh-secondary-foreground-content) !important;
    border-color: var(--gh-secondary-border) !important;
    border-width: 1px;
    font-weight: 400;
    text-transform: none;
    border-radius: 6px;

    &:hover,
    &:focus {
      background-color: var(--gh-secondary-background) !important;
      color: var(--gh-secondary-foreground-content) !important;
      border-color: var(--gh-secondary-border) !important;
      border-width: 1px;
      font-weight: 400;
      text-transform: none;
    }

    &:disabled {
      border: 1px solid var(--gh-secondary-border) !important;
      color: var(--gh-secondary-disabled-foreground-content) !important;
      background-color: var(--gh-secondary-background) !important;;
    }
  }

  &.btn-tertiary {
    background-color: var(--gh-tertiary-background) !important;
    color: var(--gh-tertiary-color) !important;
    border-color: var(--gh-tertiary-border) !important;
    border-width: 1px;
    font-weight: 600;
    text-transform: none;
    border-radius: 6px;

    &:hover,
    &:focus {
      background-color: var(--gh-tertiary-background) !important;
      color: var(--gh-tertiary-color) !important;
      border-color: var(--gh-tertiary-border) !important;
    }
  }

  &.btn-warning {
    border-color: transparent;
    color: var(--gh-danger-foreground);

    &:hover,
    &:focus {
      background-color: var(--gh-danger-hover-background) !important;
      border-color: transparent !important;
      color: var(--gh-danger-hover-foreground) !important;
    }
  }
}

// ============ INPUT ============ //
.CV2-input {
  &.form-control {
    border-radius: 8px;
    border-color: var(--gh-secondary);
    background-color: var(--gh-brand-foreground);
    padding: 10px 8px 11px;
    color: var(--gh-default-foreground);

    &.is-invalid {
      border-color: var(--gh-danger);
      background-image: none;
      &:focus {
        box-shadow: none;
        border-color: var(--gh-danger);
      }
    }

    &:focus {
      box-shadow: none;
      border-radius: 8px;
      border-color: var(--gh-primary);
    }
  }
}

.CV2-invalid-feedback {
  color: var(--gh-danger);
  font-size: 14px;
  font-weight: 400;
}

.CV2-form-check {
  label {
    font-size: 14px;
    cursor: pointer;
  }

  input {
    border-color: var(--gh-divider);
    background-color: var(--gh-brand-foreground);
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-top: 0;

    &:checked {
      background-color: var(--gh-primary);
      border-color: var(--gh-primary);
    }

    &#invalidCheck:checked {
      background-color: var(--gh-primary);
      border-color: var(--gh-primary);
    }
  }
}

.invalid-feedback {
  color: var(--gh-danger);
}

.form-control {
  border-radius: 6px;

  &:focus {
    border-radius: 6px;
    box-shadow: none;
  }

  &textarea {
    padding: 12px 8px;
    border-radius: 6px;
  }
}

// ============ HEADING ============ //
.CV2-heading {
  color: var(--gh-content-foreground);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.CV2-sub-heading {
  font-size: 24px;
  color: var(--gh-content-foreground);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

// ============ DROPDOWN ============ //
.CV2-dropdown-menu {
  &.show {
    .dropdown-toggle {
      background-color: var(--gh-default) !important;
    }
  }
}

.top-view-chart {
  .apexcharts-legend {
    justify-content: center !important;
  }
  .apexcharts-legend-text {
    padding-left: 26px !important;
    margin-left: -18px !important;
  }
}

.top-country-users-chart {
  max-width: 271px;
  .row-data {
    div:first-child {
      width: 55%;
    }
    div:last-child {
      width: 15%;
    }
  }
}

.dropdown-toggle {
  border: none;
  width: auto;
  color: var(--gh-content-foreground);
  border-radius: 8px;
  border-color: var(--gh-default) !important;
  font-weight: 400;
  padding: 8px;

  &:hover,
  &:focus {
    border: none;
    width: auto;
    color: var(--gh-content-foreground);
    background-color: var(--gh-default);
  }
  &:after {
    display: none;
  }
}

.language-dropdown-toggle {
  &.login {
    background-color: transparent !important;

    &:hover,
    &:focus {
      background-color: transparent;
    }

    &:after {
      display: none;
    }
  }
  &.non-login {
    width: 130px;

    &:after {
      color: var(--gh-brand);
      border-right: 0.4em solid transparent;
      border-left: 0.4em solid transparent;
      border-top: 0.4em solid;
    }
  }
}

.dropdown-menu.CV2-dropdown-menu {
  border-color: var(--gh-secondary);
  background-color: var(--gh-brand-foreground);
  font-size: 14px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.15);

  .dropdown-item {
    padding: 4px 8px;
    color: var(--gh-content-foreground);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    font-size: 16px;

    &:hover,
    &:active {
      border-radius: 6px;
      background: var(--gh-brand-light);
    }
  }

  &.user-dropdown-menu {
    right: 0 !important;
    left: auto !important;
    min-width: 182px;

    .dropdown-item {
      color: #545454;
    }
  }
}

.user-dropdown-btn:after {
  color: #a6a5a5;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
  border-top: 0.4em solid;
}

.top-view-chart {
  .apexcharts-legend {
    justify-content: center !important;
  }
  .apexcharts-legend-text {
    padding-left: 26px !important;
    margin-left: -18px !important;
  }
}

.top-country-users-chart {
  max-width: 271px;
  .row-data {
    div:first-child {
      width: 55%;
    }
    div:last-child {
      width: 15%;
    }
  }
}

.permissions-table {
  td,
  th {
    padding: 5px 20px 3px 20px;
    font-size: 16px;
    border: none;
    vertical-align: middle;
    line-height: 2.5;
  }

  tr {
    border-top: 1px solid var(--gh-border);
  }

  thead {
    tr {
      border-top: none;
    }

    th:first-child {
      border-top-left-radius: 8px;
    }
    th:last-child {
      border-top-right-radius: 8px;
    }

    th:not(:first-child) {
      text-align: center;
    }
  }

  tbody tr {
    cursor: pointer;
  }

  .created-time {
    line-height: 140%;
  }
}
