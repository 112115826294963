.currentOrg {
  color: white;
  text-decoration: none;
  display: inline-flex;

  svg {
    font-size: 20px;
  }
}

.org-overview-left {
  width: 100%;
  @include breakpoint(xxl) {
    width: calc(100% - 280px);
  }
}

.panel-container {
  min-height: 100%;
  padding: 0;
}

.panel-content {
  display: block;
  padding: 0;
}

.item-image {
  width: 64px;
  height: 64px;
  background: #aaa;
  margin: 0 auto;
}

.flex-grow {
  flex: 1 1;
}

.card-item-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.organisation-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.card {
  margin-bottom: 1.5rem;
  border: 0;
  flex: 1 1;
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border: 1px solid #e1e1e1;
  border-radius: 20px;
  overflow: hidden;
}

.organisation-card {
  margin: unset;
  background: linear-gradient(45deg, #7f7f7f 0%, #e6e6e6 100%);
  aspect-ratio: 345/191;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-org-icon-container {
  width: 3.75rem;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gh-secondary);
  color: var(--gh-primary);
  border-radius: 50%;
  margin-bottom: 1rem;
}

.new-org-icon {
  font-size: 2.25rem;
  color: var(--gh-primary);
}

.no-organisations-create {
  flex: 0.25;
}

.organisations-list {
  .organisation-logo {
    border-radius: 0.5rem;
    width: 3.75rem;
    height: 3.75rem;
    background: white;
    background-position: center center;
    background-size: cover;
    border: 1.5px solid var(--gh-primary-foreground);
    background-repeat: no-repeat;
    background-origin: content-box;
    padding: 0;
  }
}

.organisation-logo {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background: white;
  background-position: center center;
  background-size: contain;
  border: 3px solid $primary-light;
  background-repeat: no-repeat;
  padding: 1rem;
  background-origin: content-box;
}

.new-org-card {
  box-shadow: none;
  border: 2px dashed var(--gh-primary);
  color: var(--gh-primary);
  background: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  aspect-ratio: 345/191;
  font-family: 'Inter', sans-serif;
}
.organisations-list {
  .organisation-title {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    font-family: 'Inter', sans-serif;
  }
}

.organisation-title {
  padding-left: 1rem;
  margin-top: -5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
}

.organisation-name {
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  justify-content: center;
  color: var(--gh-brand-foreground);

  h4 {
    color: var(--gh-brand-foreground);
    font-weight: 700;
    font-size: 1rem;
  }
}

.organisation-content {
  padding: 2rem;
  padding-left: calc(150px + 2rem);
  margin-top: -5rem;

  h4 {
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 0.25rem;
  }
}

.org-member {
  padding: 0.5rem 1rem;
  margin: 0;
  max-width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  small {
    word-wrap: break-word;
    display: block;
  }

  .member-name {
    padding-bottom: 0.5rem;
  }

  .member-email {
    color: #555;
  }

  &:nth-child(even) {
    background-color: #fafafa;
  }
}

.organisation-bg {
  height: 100px;
  width: 100%;
  background: $primary-light;
  display: flex;
  justify-content: flex-end;
  // position: relative;

  .edit-toggle {
    width: 1.5rem;
    height: 1.5rem;
    color: white;
  }
}

.edit-toggle-contain {
  margin-left: 1rem;
}

.organisations-col {
  padding: unset;
}

.organisations-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding: 3.75rem 9% 9% 9%;

  @include breakpoint(sm) {
    display: grid;
    grid-template-columns: 1fr;
  }
  @include breakpoint(md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @include breakpoint(lg) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include breakpoint(xxl) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .organisaiton-card {
    height: 250px;
    background: $primary-light;
  }

  .org-card-content {
    z-index: 10;
  }

  .organisation-title {
    margin: 0;
  }

  .organisation-bg {
    min-height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: $primary-light;
  }
}
.flex-height-50 {
  width: 100%;
  margin: 0;
}

.org-members-container {
  padding: 1rem 0;
}

.org-members-card {
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.subscription-card {
  margin-bottom: 1.5rem;
  @include breakpoint(xl) {
    margin-bottom: 1.5rem;
  }
  @include breakpoint(xxl) {
    margin-bottom: 0;
  }
}

.top-container {
  height: 100%;
  @include breakpoint(xxl) {
    height: auto;
  }
}
.org-members-list {
  min-height: 100%;

  margin: 0;
  padding: 0;
  @include breakpoint(md) {
    padding: 0;
    max-width: 100%;
    width: 100%;
    margin: 0;
    margin-top: 0;
  }
  @include breakpoint(xl) {
    padding: 0;
    width: 100%;
    margin: 0;
    margin-top: 0;
  }
  @include breakpoint(xxl) {
    min-height: 100%;
    flex: 1 1;
    max-width: 280px;
    margin: 0;
    padding: 0;
    min-width: 280px;
  }
}
.org-member-img-container {
  max-width: 3rem;
  padding: 0;
}

.org-member-img {
  width: 3rem;
  height: 3rem;
  background: grey;
  border-radius: 50%;
}

.org-member-info {
  align-items: flex-start;
  justify-content: center;
  word-wrap: break-word;
  display: block !important;
}

.org-member-actions {
  display: flex;
  align-items: center;
  padding: 0;
  max-width: 1.5rem;
  justify-content: center;
}

.actions-toggle {
  width: 5px;
  height: 5px;
  background: #444;
  align-self: center;
  border-radius: 50%;

  &::before {
    content: '';
    position: absolute;
    margin-top: -10px;
    width: 5px;
    height: 5px;
    background: #444;
    border-radius: 50%;
  }
  &::after {
    content: '';
    position: absolute;
    margin-top: 10px;
    width: 5px;
    height: 5px;
    background: #444;
    border-radius: 50%;
  }
}

.user-roles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.org-role-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.invite-btn {
  background: #eee;
  align-items: center;
  padding: 1rem;
  border-radius: 0;

  &:hover,
  &:focus,
  &:active {
    background: #43bf6c !important;
  }
}

.group-list-container {
  margin-top: 2rem;
  .group-list-item {
    padding: 1rem;
  }
  .group-list-item:nth-child(odd) {
    background: #eee;
  }
}

.invite-btn {
  border: 0;
  width: 100%;
  text-align: center;
}

.invite-popup {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  // display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.isActive {
  display: flex;
}
.isActive-block {
  display: block !important;
}
.opacity {
  opacity: 1;
  transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.invite-popup-container {
  width: 100%;
  max-width: 500px;
  position: absolute;
  background: #fefefe;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  left: 0;
  position: fixed;
  top: 0;
  height: fit-content;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 3000;
  form {
    margin-top: 1rem;
  }
}

.text-left {
  text-align: left;
}

.org-card-title {
  .fit-content {
    display: flex;
    align-items: flex-start;
  }
}
.invite-popup-overlay {
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.5;
  position: fixed;
  top: 0;
  z-index: 2000;
  left: 0;
}

.invite-buttons {
  margin-top: 1rem;
  text-align: right;

  button {
    padding: 0.5rem 1rem;
    margin-left: 1rem;
    border-radius: 10;
  }
}

.stats-card {
  min-height: 100%;
}

.group-list-item {
  text-align: center;
}

.orgs-users-profile {
  margin-bottom: 1rem;
  padding: 1rem;
  background: grey;
  border-radius: 50%;
  border: 1px solid white;
  z-index: 10;

  &:not(:first-child) {
    margin-left: -1rem;
  }
  &:nth-child(2) {
    z-index: 9;
  }
  &:nth-child(3) {
    z-index: 8;
  }
  &:nth-child(4) {
    z-index: 7;
  }
  &:nth-child(5) {
    z-index: 6;
  }
}

.process-icon {
  width: 64px;
  height: 64px;
}
