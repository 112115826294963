.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding .15s);
}

@media (min-width: 768px) {
  .wrapper.new-wrapper {
    @include ltr-rtl("padding-left", 274px);
  }
}