.tab-container {
  display: flex;
  border-bottom: 1px solid grey;
  flex-direction: row;
  margin: 0.2rem;

  a {
    width: auto;
    padding: 1rem 2rem 1rem 0;
  }
}

.invite-container {
  
  display: flex;
  align-items: center;

  text-align: right;

  a {
    text-align: right;
  }

  .pending-invitations {
    padding: 10px 1rem;
    border: 1px solid $primary;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    @include breakpoint(md) {
      border: none;
    }
  }

 .users-invite {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  @include breakpoint(md) {
    border-radius: 4px
  }
  }
  padding: 1rem;
    justify-content: center;

  @include breakpoint(sm) {
    
    justify-content: flex-end;
  }
  @include breakpoint(md) {
    padding-top: 0;
    padding-bottom: 0;
  }

  a {
    text-align:  center;
    width: 100%;
    @include breakpoint(md) {
      width: auto;
    }
  }
}
.members {
  display: flex;
  align-items: center;
  border: 0;
  border-bottom: 1px solid #eee;
}
.member-img-container {
  max-width: 3rem;
  padding: 0;
  margin-right: 1rem;

  .member-img {
    width: 3rem;
    height: 3rem;
    background: grey;
    border-radius: 50%;
  }
}
.org-member-info {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.card-header {
  font-weight: bold;
  margin: 0;
  padding: 1rem;
  display: flex;
  background: white;
}

.card-noborder {
  box-shadow: none;
  border:none;
}

.users-header {
  padding: 1rem 0;
  align-items: center;
}
.member-container {
  padding: 0.5rem 1rem;

  .row {
    min-width: 270px;
  }
}

.member-role {
  color: grey;
  font-size: 70%;
}

.role {
  padding: 0.15rem 0.5rem;
  color: white;
  font-size: 80%;
  width: fit-content;
  border-radius: 30px;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  .action {
    margin-right: 1rem;
    text-decoration: none;
    color: grey;
  }
}

.header-title:nth-child(1) {
  min-width: 270px;
  padding-right: 2rem;
}
.org-member-actions {
  position: relative;
}
.actions-dropdown {
  position: absolute;
  list-style-type: none;
  right: 0;
  background: white;
  width: 100%;
  li {
    border-bottom: 1px solid grey;
  }
}



.invitation-sent {
  position: absolute;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 250px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  z-index: 10;
  display: none;
}


.success-wrapper{
  display: none;
  margin: 4rem auto 0;
  border: 1px solid #fff;
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 600px !important;
  background: #fff;
  text-align:center;
  border-radius:.6rem;
  padding: 3rem;
  padding-top: 0;
  padding-bottom:3rem;
   animation-delay:.2s;
  animation-duration:.3s;
  animation-fill-mode:forwards;
  animation-name:getFullThankyouWidth;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  transition:.5s ease-in-out;
  -o-transition:.5s ease-in-out;
  opacity: 0;
  z-index: 10000;
}
.check-mark-block{
  position:relative;
  transform:translateY(0);
  opacity:0;
  margin:auto;
  width:8rem;
  height:8rem;
  background-image: linear-gradient(to top, #99f449 0%, #05ebb5 100%);
  border-radius:.6rem;
  overflow:hidden;
   animation-delay:.4s;
  animation-duration:.3s;
  animation-fill-mode:forwards;
  animation-name:loadUpThankyou;
}
.check-mark-wrapper{
  margin:0;
  padding:0;
  position:relative;
  text-align:center;
  margin:auto;
  padding:5rem 10% 4rem 16%;
}
.check-mark-wrapper span{
  position:relative;
  width: 3rem;
    height: .7rem;
    background: transparent;
    display: block;
    border-radius: 30px;
    transform: rotate(0);
    transition: all .3s;
    transform: rotate(33deg);
    position: relative;
  filter: drop-shadow(20px 10px 10px rgba(0, 0, 0, .2));
/*     border: 1px solid #ddd; */
}
.check-mark-wrapper span:last-child{
    width: 5rem;
    height: .7rem;
    display: block;
    border-radius: 30px;
    transform-origin: top;
    transition: all .3s;
    transform: translateY(-1.7rem) translateX(1rem)rotate(-56deg);
}
.check-mark-wrapper span::before{
  content: ' ';
  position:absolute;
  top:0;
  left:0;
  width:0%;
  height:100%;
  background:#fff;
  border-radius:30px;
  animation-delay:.65s;
  animation-duration:.3s;
  animation-fill-mode:forwards;
  animation-name:getFullWidth;
}
.check-mark-wrapper span:last-child::before{
  animation-delay:.85s;
}

@keyframes getFullWidth{
  0%{
    width:0;
  }
  100%{
    width:100%;
  }
}
@keyframes getFullThankyouWidth{
  0%{
    width:0;
    opacity:0;
  }
  100%{
    width:25%;
    opacity:1;
  }
}
@keyframes loadUpThankyou{
  0%{
    transform:translateY(0);
    opacity:0;
  }
  100%{
    transform:translateY(-2.5rem);
    opacity:1;
  }
}@keyframes loadUp{
  0%{
    transform:translateY(1rem);
    opacity:0;
  }
  100%{
    transform:translateY(0);
    opacity:1;
  }
}
@media only screen and (max-device-width: 767px) {
  .thank-you-wrapper{
    animation-name: getFullThankyouWidthSmall;
  }
  @keyframes getFullThankyouWidthSmall{
  0%{
    width:0;
    opacity:0;
  }
  100%{
    width:40%;
    opacity:1;
  }
}
}
