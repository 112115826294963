.create-org-container {
  max-width: 632px;
  width: 100%;

  .create-card {
    border-radius: 0.5rem;
    margin-top: 4rem;
    width: 632px;
    padding: 1.5rem;
    box-shadow: 1px 1px 4px 0px #0000001a;
    border: none;
    background-color: var(--gh-content);

    h4 {
      font-size: 1.5rem;
      font-weight: 500;
      font-family: 'Inter', sans-serif;
      margin: 0;
    }
    input {
      border-radius: 0.5rem;
      height: 2.75rem;
      font-size: 14px;
      font-family: 'Inter', sans-serif;
      padding: 0.5rem 0.75rem;
    }
  }
  .create-org-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .form-error {
    color: red;
    display: block;
    margin-top: 0.25rem;
    font-size: 14px;
    font-weight: 400;
  }
  .form-select {
    border-radius: 0.5rem;
    height: 2.75rem;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    background-color: white;
    margin-bottom: 0 !important;
  }
  .form-control:focus,
  .form-select:focus {
    border-color: var(--gh-brand);
    border-radius: 0.5rem;
    box-shadow: none;
  }
  .btn-primary {
    height: 40px;
    border-radius: 0.5rem;
    padding: 0;
  }
  .btn-primary:focus {
    height: 40px;
    border-radius: 0.5rem;
    padding: 0;
  }
  .btn-primary:hover {
    opacity: 0.8;
  }
  .form-field-description {
    font-size: 14px;
    margin-top: 0.25rem;
    color: var(--gh-secondary-foreground);
  }
  .uploaded-logo {
    width: 100px;
    height: 100px;
    background: #aaa;
    border-radius: 8px;
    background-position: center center !important;
    background-size: contain !important;
    background-repeat: no-repeat;
    margin-right: 0;
  }
  .uploaded-logo {
    background-color: white;
  }
}
