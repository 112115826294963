.toast.swal2-popup {
  padding: 1rem !important;
}
.toast .swal2-title {
  font-size: 1rem !important;
}

.swal2-popup {
  padding: 1rem 1rem 3rem 1rem !important;
}

.swal2-actions {
  flex-direction: row-reverse;
  width: 100%;
  button {
    min-width: 150px;
    margin: 0 0.5rem;
  }
}

.swal2-styled.swal2-confirm {
  background-color: $primary !important;
}

.swal2-title {
  font-size: 1.5rem !important;
}

.swal2-container p {
  font-size: 1rem;
}
