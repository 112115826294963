// ***** OVERVIEW ***** //
.overview-card {
  color: #182939;
  height: 14rem;
  margin: 24px;
  // background-color: #6dae64;
  // box-shadow: 1px 1px 3px #50505085;
  border: 3px solid #182939;
  :hover {
    cursor: pointer;
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .card-title {
    font-size: 3rem;
  }
  .card-subtitle {
    font-size: 1.2rem;
  }
}

.news-card {
  border: 3px solid #182939;
  margin: 50px 24px 30px 24px;
  padding: 10px;
}

// ***** MEMBERS ***** //
.user-avatar {
  height: 80px;
  width: 80px;
  // border-radius: 50px;
  margin-right: 20px;
  // background-color: gray;
}

.member-policy-list {
  padding-left: 16px;
  padding-top: 10px;
  li {
    font-size: 15px;
  }
}

.member-select {
  width: 320px;
  &:focus {
    border-color: #2eb85c;
    box-shadow: 0 0 0 0.25rem #2eb85c4f;
  }
}

// ***** GROUPS ***** //
.stepper-wrapper {
  margin-top: 8px;
  display: flex;
  gap: 3px;
  border-bottom: 1px solid #cdcdcd;
}

.stepper {
  width: 100%;
  text-align: center;
  padding: 13px 0;

  span {
    font-weight: 500;
    font-size: 15px;
  }
}

.stepper-content-container {
  padding: 25px 0 40px 0;
  .stepper-search-bar {
    width: 320px;
    margin-top: 10px;
  }
}

.background-green {
  color: #333333;
  background-color: #4fa34459;
}

.background-disabled {
  color: rgb(177, 177, 177);
  background-color: rgb(240, 240, 240);
}

.group-users-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  padding-left: 0;
}
@media only screen and (max-width: 767px) {
  .group-users-wrapper {
    width: 50%;
  }
}

.group-select {
  width: 320px;
  &:focus {
    border-color: #2eb85c;
    box-shadow: 0 0 0 0.25rem #2eb85c4f;
  }
}

.group-user-avatar {
  width: 40px;
  height: 40px;
  background: grey;
  border-radius: 50%;
}

.group-policy-list,
.group-member-list {
  list-style: none;
  padding-left: 0;
  padding-top: 10px;
  li {
    font-size: 15px;
  }
  img {
    width: 15px;
    height: 15px;
    margin: 0 7px 3px 0;
  }
}

.group-list {
  list-style: none;
  padding-left: 0;
  padding-top: 10px;
  li {
    font-size: 15px;
  }
  img {
    width: 17px;
    height: 17px;
    margin: 0 7px 3px 0;
  }
}

.list-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  &:hover {
    background-color: #f4f4f4;
    border-radius: 4px;
  }
}

.remove-policy-btn,
.remove-member-btn {
  font-size: 10px;
  &:focus {
    box-shadow: none;
  }
}

.existing-member-list {
  font-size: 15px;
  img {
    width: 17px;
    height: 17px;
    margin: 0 7px 2px 0;
  }
}

.cancel-create-group-btn {
  font-size: 14px;
  color: #4f5d73;
  margin-right: 10px;
  padding: 10px 22px;
  align-self: center;
  &:hover {
    color: white;
    background-color: #697588;
    border-radius: 4px;
  }
}

input.form-control.access-group-form {
  border-color: #e7e7e7 !important;
}

// ***** POLICIES ***** //

.policy-name-wrapper {
  padding: 8px 0;
}
.policy-description-title {
  font-weight: 600;
  margin-right: 50px;
}

.policy-groups-col {
  border-right: 1px solid #cbcbcb;
  padding: 0 24px 16px 24px;
  img {
    width: 23px;
    height: 23px;
    margin: 0 7px 0 0;
  }
}

.policy-members-col {
  padding: 0 24px 16px 24px;
  img {
    width: 22px;
    height: 22px;
    margin: 0 8px 0 0;
  }
}

.policy-keys-col {
  img {
    width: 20px;
    height: 20px;
    margin: 0 6px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .policy-users-col,
  .policy-keys-col {
    border-right: 0;
    border-bottom: 1px solid #aaa;
  }
}

.group-wrapper,
.member-wrapper,
.key-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  padding-left: 0;
  span {
    font-size: 15px;
  }
  span.no-result {
    color: gray;
  }
}

.policy-avatar {
  width: 40px;
  height: 40px;
  background: grey;
  border-radius: 50%;
  margin-right: 20px;
}

.min-h-100 {
  min-height: 100%;
}
.groups-display {
  flex-direction: row;
  margin: 0;

  .group-label {
    width: fit-content;
    background: #cecece;
    margin: 0.25rem;
    border-radius: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}

// .edit-group-col {
//   border-right: 1px solid #cccccc;
// }

@media only screen and (max-width: 767px) {
  .edit-group-col {
    border-right: 0;
  }
}

/// switch
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2eb85c;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2eb85c;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.user-img {
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  background: grey;
  border-radius: 50%;
}

.user-details {
  flex-direction: column;
  display: flex;
  padding-left: 1rem;
}


.group-description {
  border-radius: 8px;
}

.members-and-policies {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  .divide {
    width: 1px;
  }

  [class*="existing"] {
    max-width: 50%;
  }
}

.action-btn-block {
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.15);

  .action-btn {
    padding: 8px 16px;
    color: var(--gh-content-foreground);
    border-color: #d6d6d5;
    font-weight: 400;
    text-transform: none;

    &:hover,
    &:focus {
      padding: 8px 16px;
      color: var(--gh-content);
      border-color: #d6d6d5;
      font-weight: 400;
      text-transform: none;
    }

    &.delete {
      border-color: transparent;
      color: var(--gh-danger);
      font-weight: 700;

      &:hover,
      &:focus {
        background-color: transparent !important;
        border-color: transparent !important;
        color: var(--gh-danger);
      }
    }
  }
}