
.edit-org-info {
    max-width: 450px;
  }
  
  .center-content{
    justify-content: center;
      display: flex;
      flex-direction: column;
  }
  
  .empty-logo, .uploaded-logo {
    width: 100px;
    height: 100px;
    background: #aaa;
    border-radius: 50%;
    background-position: center center !important;
    background-size:contain !important;
    background-repeat: no-repeat;
    margin-right: 2rem;
  }
  .uploaded-logo {
    background-color: white;
  }
  
  .edit-org {
    position: relative;

    .btns-container {
        display:flex;
        justify-content: flex-end;

        // @include breakpoint(md) {
        //     position: absolute;
        //     top: 1rem;
        //     right: 3rem;
        // }
    }
  }
  .edit-org p{
    margin:.5rem 0;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
  }