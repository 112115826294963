.image-select-area {
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 1rem;
  position: relative;
  background: #fafafa;
  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
    border-radius: 10px;
  }

  button {
    cursor: pointer !important;
  }
  .remove-btn {
    cursor: pointer;
  }
  .preview-image {
    position: relative;
    max-width: 100px;
    height: 100px;
    margin-right: 1rem;
    border-radius: 10px;

    img {
      width: 100%;
    }

    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: none;
    }
    &:hover {
      cursor: grab;
    }
  }
  .delete {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 15px !important;
    height: 15px;
    opacity: 1;
    z-index: 100;
    
  }

  .placeholder-img {
    width: 100px;
    height: 100%;
    cursor: pointer;
  }
}

.product-cover-image {
  border: 1px solid #ccc;
  border-radius: 20px;
  min-width: 625px;
  min-height: 650px;
  max-height: 1000px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .single-image {
    width: fit-content;
  }
  .preview-image {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: center;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    max-height: 700px;
  }
  &:hover .btn-overlay {
    opacity: 1 !important;
  }

  .btn-overlay {
    z-index: 100;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);
  }
  .product-placeholder {
    width: 100%;
    padding: 25%;
    cursor: pointer;
  }
}
.product-edit {
  padding: 0 3rem;
  input {
    border-radius: 5px;
    margin: 0.5rem 0 !important;
  }

  label {
    margin-bottom: 0;
  }
}

.opacity-0 {
  opacity: 0;
}

.external-link-container {
  flex-wrap: wrap;
  width: 100%;
  min-height: 100px;
  background: #fafafa;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 1rem;
  .btn {
    margin-left: 2rem;
    height: fit-content;
  }
}
.additional-btn-contain {
  border-radius: 10px;
  width: calc(50% - 3rem);
  padding: 0 1rem;
}

.remove-btn {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: red;
}
.additional-btn-text {
  padding: 0.5rem;
  width: 100%;
  border: 1px solid #eaeaea;
  margin: 0 !important;
}

.add-btn {
  width: calc(50% - 6rem);
  margin: 3rem;
  // margin-bottom: 5rem;
}

.certs .secondary-img {
  max-height: 150px;
  object-fit: contain;
}

.product-edit .options {
  justify-content: space-between;
}


.repeater .additional-btn-contain {
  padding-bottom: 1rem;
  
}
