.page-link.active,
.active > .page-link {
  background-color: #2eb85c;
  border-color: #2eb85c;
}

.sidebar-nav .nav-link {
  position: relative;
}
.sidebar-nav .nav-link.active {
  // background: $primary-dark;
  // border-top-left-radius: 30px;
  // border-bottom-left-radius: 30px;
  // margin-left: 16px;

  // color: blanchedalmond;
  // transition: 0.5s ease-in-out;
}
.sidebar-nav .nav-link {
  // border-top-left-radius: 30px;
  //   border-bottom-left-radius: 30px;
  transition: 0.5s ease-in-out;
  padding: 1.25rem;
}
.nav-group-items .nav-link {
  padding: 1rem;
}
.sidebar-nav .nav-link:hover {
  // background: $primary-dark;
  // border-top-left-radius: 30px;
  //   border-bottom-left-radius: 30px;
  // margin-left: 16px;
  // color: blanchedalmond;
  // transition: 0.5s ease-in-out;
}

.nav-group-items .nav-link.active {
  // margin-left:2rem;
}

.sidebar-nav .nav-group.show {
  background-color: $primary;
}

.sidebar-nav .nav-group.show .nav-group-toggle {
  padding-left: 1.75rem;
  position: relative;
  background: $primary !important;
  color: white;
}

.sidebar-nav .nav-group.show .nav-group-toggle:hover {
  background: $primary !important;
}

.sidebar {
  background: white !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
}

.sidebar-brand {
  // border-top-right-radius: 30px;
  background: white;
}

.sidebar-nav {
  background: $primary;
  // border-bottom-right-radius: 30px;
}
